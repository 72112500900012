<template>
  <div class="relative h-screen max-w-screen bg-coolGray-900 font-stolzl flex flex-col justify-between">
    <div class="w-full h-full flex flex-col justify-center items-center">

      <!-- NEWSLETTER-->
      <div class="w-full md:w-3/4 flex flex-col justify-center items-center px-4 md:px-8">
        <div class="w-auto h-auto overflow-hidden">
          <div :ref="setRef" data-anim="0" class="font-semibold text-4xl md:text-6xl text-coolGray-50 opacity-0">Hello, let's climb.</div>
        </div>
        <div class="w-full h-auto overflow-hidden mt-3 flex flex-col justify-center items-center">
          <div v-if="!submitted" :ref="setRef" data-anim="0" class="font-light text-xl md:text-2xl text-coolGray-50 w-full md:w-3/4 text-center opacity-0">
            <p>{{text.newsletter}}</p>
          </div>
        </div>
        <form v-if="!submitted" id="newsletter" @submit.prevent="submit" class="flex flex-row justify-center items-center w-full md:w-4/6 px-4 py-4 bg-white mt-5">
          <input v-model="email" type="email" placeholder="Email" class="font-light text-coolGray-900 placeholder-coolGray-900 appearance-none outline-none w-8/12 md:w-9/12"/>
          <input type="submit" :value="text.send" class="font-light text-right text-coolGray-900 placeholder-coolGray-900 bg-white appearance-none outline-none w-4/12 md:w-3/12 cursor-pointer"/>
        </form>
        <div v-else class="font-light text-xl md:text-2xl text-coolGray-50 mt-3 w-full md:w-3/4 text-center">{{text.success}}</div>
      </div>
      <!-- NEWSLETTER-->

      <!-- DEV LINK-->
      <a href="https://the-frontend.hamburg/" target="_blank" class="md:absolute md:bottom-20 w-full px-4 md:px-8 mt-12 -mb-24 md:mb-0 md:mt-0 flex flex-row justify-center md:justify-start items-center cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" class="w-2/3 md:w-2/12 h-auto" viewBox="0 0 744 180" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-23.5229,-210.918)">
        <g transform="matrix(2.29416,0,0,2.29416,-457.313,-502.094)">
            <path d="M240.555,355.029L240.555,347.157L210.027,347.157L210.027,388.309L219.499,388.309L219.499,372.693L237.291,372.693L237.291,364.821L219.499,364.821L219.499,355.029L240.555,355.029Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M274.091,371.413C277.803,368.853 279.275,364.885 279.275,360.469C279.275,352.277 274.859,347.157 261.611,347.157L244.331,347.157L244.331,388.309L253.803,388.309L253.803,374.485L264.363,374.485C267.755,374.485 268.587,375.957 268.587,378.709L268.651,388.309L278.059,388.309L278.059,378.709C278.059,374.293 276.843,372.309 274.091,371.413ZM253.803,355.029L261.611,355.029C266.667,355.029 269.931,356.501 269.931,360.469C269.931,364.693 266.667,366.677 261.611,366.677L253.803,366.677L253.803,355.029Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M281.771,367.765C281.771,380.117 290.411,388.949 303.979,388.949C317.611,388.949 326.187,380.117 326.187,367.765C326.187,355.349 317.611,346.517 303.979,346.517C290.411,346.517 281.771,355.349 281.771,367.765ZM316.331,367.765C316.331,375.957 312.107,380.885 303.979,380.885C296.107,380.885 291.627,375.957 291.627,367.765C291.627,359.637 296.107,354.581 303.979,354.581C312.107,354.581 316.331,359.637 316.331,367.765Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M360.043,347.157L360.043,376.085L339.627,347.157L329.643,347.157L329.643,388.309L339.115,388.309L339.115,360.213L359.211,388.309L369.515,388.309L369.515,347.157L360.043,347.157Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M408.747,347.157L373.035,347.157L373.035,355.029L386.219,355.029L386.219,388.309L395.691,388.309L395.691,355.029L408.747,355.029L408.747,347.157Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M421.675,380.437L421.675,371.669L441.131,371.669L441.131,363.797L421.675,363.797L421.675,355.029L444.011,355.029L444.011,347.157L412.203,347.157L412.203,388.309L444.651,388.309L444.651,380.437L421.675,380.437Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M478.827,347.157L478.827,376.085L458.411,347.157L448.427,347.157L448.427,388.309L457.899,388.309L457.899,360.213L477.995,388.309L488.299,388.309L488.299,347.157L478.827,347.157Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
            <path d="M511.851,347.157L492.715,347.157L492.715,388.309L511.851,388.309C524.651,388.309 533.483,381.141 533.483,367.765C533.483,354.389 524.651,347.157 511.851,347.157ZM511.851,380.437L502.187,380.437L502.187,355.029L511.851,355.029C520.363,355.029 524.011,359.509 524.011,367.765C524.011,374.997 520.363,380.309 511.851,380.437Z" style="fill:rgb(232,246,217);fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(2.29416,0,0,2.29416,-473.23,-476.33)">
            <path d="M238.287,306.439C235.648,306.509 233.889,307.6 232.798,309.183C231.848,307.424 230.019,306.369 226.676,306.439C224.389,306.509 222.876,307.424 221.926,308.796L221.926,306.791L216.965,306.791L216.965,322.624L222.137,322.624L222.137,315.622C222.137,312.491 222.806,310.485 225.902,310.485C227.415,310.485 228.541,311.752 228.541,314.144L228.541,322.624L233.713,322.624L233.713,315.622C233.713,312.491 234.417,310.485 237.478,310.485C239.026,310.485 240.117,311.752 240.117,314.144L240.117,322.624L245.324,322.624L245.324,314.144C245.324,309.676 244.093,306.298 238.287,306.439Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M264.113,318.296C263.445,318.296 263.198,318.015 263.198,317.1L263.198,316.467L263.163,312.139C263.163,308.585 260.419,306.439 255.176,306.439C247.752,306.439 247.365,311.576 247.365,311.576L252.502,311.576C252.502,311.576 252.608,310.204 255.141,310.204C257.006,310.204 258.097,310.661 258.097,312.104L258.097,312.42C255.141,312.561 246.591,312.702 246.591,318.156C246.591,320.689 248.35,322.976 252.96,322.976C255.774,322.976 257.639,322.202 258.765,320.865C259.574,322.202 261.193,322.624 264.148,322.624L264.289,322.624L264.289,318.296L264.113,318.296ZM254.05,319.317C252.854,319.282 251.834,318.789 251.834,317.98C251.798,315.833 255.458,315.622 258.097,315.446L258.097,316.044C258.097,318.402 255.845,319.352 254.05,319.317Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M278.363,300L278.363,308.48C277.589,307.424 276.393,306.65 274.493,306.509C268.617,306.087 265.239,310.098 265.239,314.743C265.239,319.633 268.547,323.398 274.458,322.906C276.463,322.73 277.695,321.85 278.469,320.654L278.469,322.624L283.536,322.624L283.536,300L278.363,300ZM274.423,318.824C272.417,318.824 270.517,317.733 270.517,314.743C270.517,311.787 272.347,310.591 274.423,310.591C276.534,310.591 278.469,311.294 278.469,314.707C278.469,317.804 276.604,318.824 274.423,318.824Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M293.845,306.439C288.462,306.439 285.26,309.957 285.26,314.743C285.26,319.774 288.884,322.976 293.915,322.976C300.776,322.976 302.008,317.804 302.008,317.804L296.871,317.804C296.871,317.804 296.378,318.894 293.95,318.894C292.156,318.894 290.537,317.944 290.291,316.15L302.254,316.15C302.747,309.43 298.736,306.439 293.845,306.439ZM293.88,310.52C295.815,310.52 297.293,311.365 297.328,312.913L290.326,312.913C290.608,311.506 291.91,310.52 293.88,310.52Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M321.923,306.509C319.988,306.65 318.791,307.459 318.017,308.515L318.017,300L312.81,300L312.81,322.624L317.912,322.624L317.912,320.619C318.686,321.815 319.917,322.73 321.958,322.906C327.834,323.398 331.141,319.633 331.141,314.743C331.141,310.098 327.763,306.087 321.923,306.509ZM321.958,318.824C319.776,318.824 317.947,317.804 317.947,314.707C317.947,311.294 319.847,310.591 321.958,310.591C324.034,310.591 325.899,311.787 325.899,314.743C325.899,317.733 323.999,318.824 321.958,318.824Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M343.351,306.791L339.621,316.713L336.102,306.791L330.754,306.791L337.404,322.624L337.088,323.433C336.665,324.454 336.278,324.735 334.976,324.735L331.986,324.735L331.986,329.063L334.976,329.063C339.586,329.063 340.395,327.339 341.908,323.433L348.699,306.791L343.351,306.791Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M368.086,306.791L364.356,306.791L364.356,302.146L359.149,302.146L359.149,306.791L357.249,306.791L357.249,311.119L359.149,311.119L359.149,316.994C359.149,321.815 360.838,322.624 365.658,322.624L368.086,322.624L368.086,318.296L365.658,318.296C364.673,318.296 364.391,317.98 364.391,316.994L364.391,311.119L368.086,311.119L368.086,306.791Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M379.732,306.439C377.621,306.474 376.178,307.248 375.228,308.444L375.228,300L370.021,300L370.021,322.624L375.228,322.624L375.228,315.622C375.228,312.913 376.073,310.45 379.099,310.485C381.104,310.52 381.914,312.033 381.914,314.567L381.914,322.624L387.121,322.624L387.121,314.567C387.121,310.063 385.89,306.333 379.732,306.439Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
            <path d="M397.043,306.439C391.66,306.439 388.458,309.957 388.458,314.743C388.458,319.774 392.082,322.976 397.114,322.976C403.975,322.976 405.206,317.804 405.206,317.804L400.069,317.804C400.069,317.804 399.577,318.894 397.149,318.894C395.354,318.894 393.736,317.944 393.49,316.15L405.453,316.15C405.945,309.43 401.934,306.439 397.043,306.439ZM397.078,310.52C399.014,310.52 400.491,311.365 400.527,312.913L393.525,312.913C393.806,311.506 395.108,310.52 397.078,310.52Z" style="fill:none;fill-rule:nonzero;stroke:rgb(232,246,217);stroke-width:0.87px;"/>
        </g>
    </g>
        </svg>
      </a>
      <!-- DEV LINK-->

    </div>
    <!-- BOTTOM LINE-->
    <div class="w-full flex flex-col md:flex-row justify-between items-center p-8 mb-32 md:mb-0">
      <div class="flex flex-row justify-center items-center">
        <div class="text-xs font-extralight text-coolGray-200 cursor-pointer" @click="changeLang('DE')">Deutsch</div>
        <div class="text-sm font-extralight text-coolGray-200 px-2">|</div>
        <div class="text-xs font-extralight text-coolGray-200 cursor-pointer" @click="changeLang('EN')">English</div>
      </div>
      <div class="flex flex-col md:flex-row justify-center items-center text-xs font-extralight text-coolGray-500">
        <router-link :to="{ name: 'AGB' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.abg}}</router-link>
        <router-link :to="{ name: 'Datenschutz' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.datenschutz}}</router-link>
        <router-link :to="{ name: 'Widerrufsbelehrung' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.widerrufsbelehrung}}</router-link>
        <router-link :to="{ name: 'Content', params: { slug: 'kontakt' } }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.kontakt}}</router-link>
        <router-link :to="{ name: 'Impressum' }" class="transition duration-200 ease-out cursor-pointer mx-5 hover:text-coolGray-50 py-1 md:py-0">{{text.impressum}}</router-link>
        <a href="https://www.instagram.com/rewhiteclimbing/" target="_blank" class="group flex flex-row justify-center items-center py-1 mx-5 gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out w-4 h-4 group-hover:text-coolGray-50 fill-current" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
          <div class="transition duration-200 ease-out cursor-pointer group-hover:text-coolGray-50  md:py-0">Instagram</div>
        </a>
      </div>
      <div class="flex flex-row justify-center items-center text-coolGray-500">
        <div class="text-lg font-extralight pt-1.5">©</div>
        <div class="text-xs font-extralight">rewhite-climbing 2021</div>
      </div>
    </div>
    <!-- BOTTOM LINE-->

  </div>
</template>

<script>
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'Footer',
  mixins: [intersectionObserver],

  data() {
    return {
      email: null,
      submitted: false,
    };
  },

  computed: {
    text() {
      return this.$store.state.lang.footer[this.$store.state.lang.currentLang];
    },
    lang() {
      return this.$store.state.lang.currentLang;
    },
  },

  mounted() {
    this.submitted = false;
  },

  methods: {

    changeLang(lang) {
      this.$store.commit('setLang', lang);
    },

    submit() {
      this.submitted = true;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: this.email, lang: this.lang }),
      };
      fetch('https://hook.integromat.com/y2okwqpr0kv8gdtemvybpf4y2e5g7dm6', requestOptions);
    },

  },
};
</script>
